/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import './theme/dsfr.min.css';
@import './theme/utility.min.css';
@import './theme/dsfr.override.css';
@import "./theme/dsfr.select.css";

ion-modal.auto-height {
    --backdrop-opacity: 0.8;
    --ion-background-color: transparent;
    --ion-box-shadow-color: transparent;
    --height: auto;
}

ion-content {
    --ion-background-color: rgba(238, 238, 238, 0.25);
}

.fr-toast--success {
    --color: #18753c;
    --border-color: #b8fec9;
    --border-radius: 0px;
    --border-style: solid;
    --border-width: 1px;
    --background: #b8fec9;
    --button-color: #18753c;
}

.fr-toast--error {
    --color: #ce0500;
    --border-color: #ffe9e9;
    --border-radius: 0px;
    --border-style: solid;
    --border-width: 1px;
    --background: #ffe9e9;
    --button-color: #ce0500;
}
