#header {
    z-index: 10000
}

a.anchor {
    display: block;
    position: relative;
    top: -8.5rem;
    visibility: hidden
}

body {
    overflow-x: hidden
}

.submenu-logo {
    align-items: center;
    display: flex;
    height: 100%
}

.submenu-logo img {
    border-radius: 50%;
    height: 2rem;
    padding: .25rem;
    width: 2rem
}

.dash-header {
    position: relative
}

.dash-header .fr-container:after,
.dash-header .fr-container:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1
}

.dash-header .fr-container:after {
    background-color: rgba(0, 0, 145, .75)
}

.dash-header .fr-breadcrumb,
.dash-header p {
    color: var(--text-inverted-grey)
}

.dash-header .fr-breadcrumb__link[aria-current],
.dash-header h1 {
    color: var(--w)
}

.dash-header .submenu-logo img {
    background-color: #11ffee00;
    height: 2.5rem;
    width: 2.5rem
}

.submenu-icon {
    align-items: center;
    display: flex
}

.submenu-icon svg {
    fill: currentColor;
    height: 1rem;
    width: 1rem
}

.submenu .fr-nav__link {
    display: flex;
    font-size: 1.5vh;
    font-weight: 400;
    justify-content: center;
    min-height: 3.5rem;
    padding: 1rem
}

.submenu .fr-nav__link[aria-current]:before {
    bottom: 0;
    height: 2px;
    left: 0;
    margin-top: 0;
    position: absolute;
    top: auto;
    width: 100%
}

.divider {
    border-bottom: 1px solid var(--border-default-grey)
}

.firstcharacter {
    color: var(--g800-plain);
    float: left;
    font-size: 4rem;
    line-height: 1;
    margin-right: 2px
}

.oval {
    border: 1px solid var(--border-default-grey);
    height: 2rem;
    padding: .5rem .5rem .5rem .45rem !important;
    width: 2rem
}

.lvl2-header {
    align-items: unset;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: unset
}

.overview {
    --col-width: 14.28571%;
    background-color: var(--beige);
    flex-grow: 2;
    margin-left: calc(var(--col-width)*-1);
    margin-right: -1rem;
    padding-left: var(--col-width);
    padding-right: 1rem
}

.overview .widget {
    height: 100%
}

@media (min-width:62em) {
    .overview {
        --remaining-space: calc(50vw - var(--col-width)*12/2);
        margin-right: calc(var(--remaining-space)*-1);
        padding-right: var(--remaining-space)
    }
}

.submenu-entry {
    display: none
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 30001;
}

.sticky-btn {
    bottom: 1.5rem;
    height: 2rem;
    min-height: 2rem;
    min-width: 2rem;
    padding: 0;
    position: fixed;
    right: 1rem;
    width: 2rem
}

section.fr-grid-row {
    position: relative;
    width: 100%
}

@media (min-width:48em) {
    a.anchor {
        top: -5rem
    }

    .sticky-btn {
        display: none !important
    }

    .lvl2-header {
        align-items: baseline;
        flex-direction: row;
        justify-content: space-between
    }

    .fr-sidemenu--sticky-full-height {
        top: 3.5rem
    }

    .submenu-entry {
        display: inline;
        white-space: nowrap
    }

    .submenu-entry2 {
        display: none
    }

    .firstcharacter {
        font-size: 4.5rem
    }

    section.fr-grid-row:before {
        content: ""
    }

    .fr-sidemenu__inner {
        padding-right: 1rem
    }

    .fr-sidemenu__inner .fr-sidemenu__link {
        padding-right: 0
    }
}

@media (max-width:48em) {
    .submenu .fr-nav__link {
        align-items: center;
        flex-direction: column;
        padding: .5rem 1rem
    }
}

@media (min-width:62em) {

    .overview,
    section.fr-grid-row:before {
        --col-width: 12.5%
    }
}

.box {
    border: 1px solid var(--border-default-grey);
    width: 100%
}

.box .widget {
    height: 100%
}

.box .fr-tabs {
    margin: 0
}

.box .fr-tabs:after {
    box-shadow: none
}

.fr-tile--horizontal .fr-tile__img {
    height: 3rem;
    margin: 1.5rem 0 1.5rem 1.5rem;
    width: 3rem
}

.fr-accordion .fr-accordion__btn {
    padding: .75rem 1rem
}

.fr-accordion .fr-collapse {
    padding-left: 1rem;
    padding-right: 1rem
}

.fr-accordion .fr-collapse--expanded {
    padding-bottom: 1rem
}

@media (hover:hover) {
    .tooltip {
        position: relative
    }

    .tooltip a:before {
        background: var(--w);
        box-shadow: 0 1px 4px 1px hsla(0, 0%, 9%, .08), 0 2px 4px -4px hsla(0, 0%, 9%, .08);
        color: var(--text-action-high-blue-france);
        content: "Copier le lien";
        font-size: .875rem !important;
        line-height: 1.5rem !important;
        padding: .25rem .5rem;
        text-align: center;
        transform: translateX(-50%) translateX(.375rem);
        white-space: pre
    }

    .tooltip a:after,
    .tooltip a:before {
        display: none;
        left: 50%;
        position: absolute;
        top: 100%
    }

    .tooltip a:after {
        border: .5rem solid #11ffee00;
        border-bottom: .5rem solid var(--background-default-grey);
        content: "";
        filter: drop-shadow(0 -1px 1px rgba(22, 22, 22, .08));
        transform: translateX(-50%) translateX(.375rem) translateY(-100%)
    }

    .tooltip :hover:after,
    .tooltip :hover:before {
        display: block;
        margin-top: .5625rem
    }
}

.fr-warning {
    margin-left: -1.5rem !important;
    min-width: calc(100% + 3rem) !important
}

#header .fr-container,
.dash-header .fr-container {
    max-width: 1136px
}

@media screen and (min-width:78em) {

    #header .fr-container,
    .dash-header .fr-container {
        padding-left: 0;
        padding-right: 0
    }
}

#header .fr-header__search .fr-input {
    padding-top: .5rem
}

#header .fr-header__logo .fr-logo:before {
    margin-bottom: .3rem
}

#header .fr-header__logo .fr-logo:after {
    margin-top: .2rem
}

.fr-alert {
    box-shadow: inset 0 0 0 1px var(--border-default-grey), inset 2.5rem 0 0 0 var(--border-default-grey);
    padding: 1rem 2.25rem .75rem 3.5rem;
    position: relative
}

.fr-alert p {
    margin: 0 0 .25rem
}

.fr-alert .fr-alert__title {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem
}

.fr-alert.fr-alert--info {
    box-shadow: inset 0 0 0 1px var(--border-plain-info), inset 2.5rem 0 0 0 var(--border-plain-info)
}

.fr-alert.fr-alert--error {
    box-shadow: inset 0 0 0 1px var(--border-plain-warning), inset 2.5rem 0 0 0 var(--border-plain-warning)
}

.fr-alert.fr-alert--sm {
    padding: .5rem 2.25rem .25rem 3rem
}

.fr-alert.fr-alert--sm:before {
    padding: .5rem
}

.fr-sidemenu__btn:before,
.fr-sidemenu__inner>.fr-sidemenu__btn:after {
    font: normal normal normal 1rem/1 dsfr-icon !important;
    --webkit-mask-image: url(../assets/icons/system/filter-line.svg);
    mask-image: url(../assets/icons/system/filter-line.svg);
    --webkit-mask-size: 100% 100%;
}

.loading .chart,
.loading.data_box p,
.loading .evol_box,
.loading .map,
.loading .scale,
.loading [data-box=number] .l_box_title:after,
.loading [data-box=number] .l_box_title:before,
.loading div[data-box]>p {
    animation: loading 1.5s linear infinite;
    background: linear-gradient(110deg, #eee 8%, var(--background-alt-grey) 18%, #eee 33%);
    background-size: 200% 100%;
    color: #11ffee00 !important
}

.loading .l_box_trend span,
.loading .r_col .flex,
.loading svg {
    display: none !important
}

.loading.data_box {
    border-bottom-color: #11ffee00 !important
}

.loading.data_box p:nth-child(2) {
    height: 4.5rem
}

.loading .om_container .om,
.loading .scale .l_box_title,
.loading .scale_container {
    visibility: hidden
}

.loading [data-box=number] .l_box_title {
    margin-bottom: 10.5rem !important;
    position: relative
}

.loading [data-box=number] .l_box_title:before {
    content: "";
    height: 4.5rem;
    left: 0;
    position: absolute;
    top: calc(100% + .5rem);
    width: 100%
}

.loading [data-box=number] .l_box_title:after {
    content: "";
    height: 3.5rem;
    left: 0;
    position: absolute;
    top: calc(100% + 5.5rem);
    width: 100%
}

.loading .r_col {
    align-self: stretch !important
}

.loading .r_col .chart,
.loading .r_col .map {
    height: 100% !important;
    min-height: 15rem
}

@keyframes loading {
    to {
        background-position-x: -200%
    }
}

.background-blue-ecume-main-400 {
    background-color: #0063cb !important;
}

.background-blue-ecume-moon-675 {
    background-color: #b9c6e1 !important;
}


.no-container {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: absolute;
    right: 50%;
    top: 0;
    width: 100vw;
    z-index: -1;
}

.fr-container-relative {
    position: relative;
}

.fr-breadcrumb.dark,
.fr-breadcrumb.dark .fr-breadcrumb__link[aria-current] {
    color: #fff !important;
}

.overflow-hidden {
    overflow: hidden;
}

.fr-header {
    z-index: 1;
}

@media (max-width: 48em) {
    #content {
        padding-left: 0 !important
    }
}

@media (max-width: 36em) {
    .fr-table table {
        display: block;
    }
}

.mcf-field-separator {
    min-width: 256px;
    max-width: 376px;
    margin: 16px auto;
}

.mcf-field-separator,
.mcf-separator {
    position: relative;
    width: 100%;
    margin: 8px auto;
    text-align: center;
    font-size: 12px;
    color: #4e6ead;
}

.mcf-field-separator--bg-default:before {
    background-color: #f6f8fb;
}

.mcf-field-separator:before,
.mcf-separator:before {
    z-index: 1;
    content: attr(aria-label);
    position: relative;
    padding: 0 11px;
    background-color: #fff;
}

.mcf-field-separator:after,
.mcf-separator:after {
    z-index: 0;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    height: 1px;
    background-color: #4e6ead;
    border-radius: 0.5px;
}

.fr-btn.full-width-button {
    display: block;
    text-align: center;
    width: 100%;
}

.login-form-body {
    background-color: var(--grey-975-75);
}

.fr-center {
    justify-content: center;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fr-container-right--responsive {
    padding-left: 0 !important;
}

@media (min-width: 48em) {
    .fr-container-right--responsive {
        padding-left: 2rem !important;
    }
    .fr-container-left--responsive {
        padding-right: 2rem !important;
    }
}

@media (min-width: 62em) {
    .fr-container-right--responsive {
        padding-left: 2rem !important;
    }
    .fr-container-left--responsive {
        padding-right: 2rem !important;
    }
}

span[class*='fr-icon-'].blue {
    color: #0063cb;
}

button[class*='fr-icon-'].blue {
    background-color: #0063cb;
}

span[class*='fr-icon-'].red {
    color: #f60700;
}

button[class*='fr-icon-'].red {
    background-color: #f60700;
}

span[class*='fr-icon-'].green {
    color: #27a658;
}

button[class*='fr-icon-'].green {
    background-color: #27a658;
}

.fr-table td,
.fr-table th {
    white-space: nowrap;
}

.fr-table--fit table thead th.fit,
.fr-table--fit table tbody td.fit {
    width: 1%;
}

.fr-table--fit table tbody td.ellipsis {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.fr-callout.fr-callout--max-height {
    height: 100%;
}

@media (min-width: 48em) {
    .fr-modal__body {
        max-height: fit-content!important;
    }
}

.fr-container--fluid.fixed {
    position: fixed;
    background: #fff;
    bottom: 0;
    width: 100%;
    z-index: 30001;
}

a[aria-disabled=true] {
    color: #cecece;
}

progress::-moz-progress-bar {
    background: blue;
}

progress::-webkit-progress-value {
    background: blue;
}

.fr-progress {
    border-bottom: 2px solid #161616;
    background-color: #eee;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: inset 0 -2px 0 0 #161616;
    display: block;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    color: #000091;
}

.fr-progress::-webkit-progress-bar {
    background-color: #eee;
    width: 100%;
}

.fr-progress::-webkit-progress-value {
    background-color: #6a6af4 !important;
}

.submenu.submenu--bottom .fr-nav__link[aria-current]:before {
    bottom: 1px;
}

.fr-header--bottom {
    box-shadow: 0 -8px 8px 0 rgb(0 0 0 / 10%), 0 8px 16px -16px rgb(0 0 0 / 32%) !important;
}

.submenu.submenu--top .fr-nav__link {
    min-height: 2.5rem;
}

.fr-btn.fr-btn--full {
    justify-content: center;
    width: 100%;
}

span[class*='fr-icon-'].blue {
    color: #0063cb;
}

button[class*='fr-icon-'].blue {
    background-color: #0063cb;
}

span[class*='fr-icon-'].red {
    color: #f60700;
}

button[class*='fr-icon-'].red {
    background-color: #f60700;
}

span[class*='fr-icon-'].green {
    color: #27a658;
}

button[class*='fr-icon-'].green {
    background-color: #27a658;
}

.fr-tile-plus {
    background: #ececfe;
    box-shadow: none;
    border: 1px dashed #000091;
    cursor: pointer;
}

.fr-tile-plus .fr-tile__body {
    align-items: center;
    position: relative;
}

.fr-tile__body .fr-tile__body--close {
    position: absolute;
    top: 5px;
    right: 16px;
}

@media (min-width: 48em) {
    .submenu-logo .fr-pt-md-0,
    .submenu-logo .fr-py-md-0 {
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;
    }
}

.fr-fieldset__content.fr-fieldset__group {
    display: flex;
    padding: 0.4rem;
    background: #f2f2f9;
}
.fr-fieldset__content.fr-fieldset__group .fr-radio-group {
    flex: auto;
}

.fr-fieldset__content.fr-fieldset__group .fr-radio-group input {
    -webkit-appearance: none;
    appearance: none;
    visibility: hidden;
}

.fr-fieldset__content.fr-fieldset__group .fr-radio-group input[type=radio]+label:before,
.fr-fieldset__content.fr-fieldset__group .fr-radio-group input[type=radio]:checked+label:before {
    -webkit-appearance: none;
    appearance: none;
    visibility: hidden;
}

.fr-fieldset__content.fr-fieldset__group .fr-radio-group input[type=radio]:checked+label {
    box-shadow: inset 0 0 0 1px #000091;
    background: #fff;
    *padding: 0.4rem;
    margin: 0;
    color: #000091;
}

.fr-fieldset__content.fr-fieldset__group .fr-radio-group label {
    cursor: pointer;
    display: block;
    margin: 0;
    margin-right: 0 !important;
    padding: 0;
    padding: 0.4rem;
    text-align: center;
    width: 100%;
}

.fr-container.fr-container--highlight {
    background: #f2f2f9;
}

.fr-text--center {
    text-align: center;
}

.fr-text--right {
    text-align: right;
}

.fr-radio-group.fr-radio-group--sm input[type=radio]+label {
    *margin-left: 1.5rem;
    padding: 0.5rem 0;
}

.fr-radio-group--sm input[type=radio]+label:before {
    margin-left: 0.5rem;
    margin-top: 0.75rem;
}

.fr-th--full {
    width: 100%;
}

.fr-header__logo .fr-logo:after {
    background-image: none;
    padding-top: 0;
}

.fr-header__service-title {
    font-size: 1.5rem;
}

.fr-table.fr-table--spaced table {
    border-spacing: 6px;
    background: transparent;
}
.fr-table.fr-table--spaced table thead,
.fr-table.fr-table--spaced table tbody tr {
    box-shadow: none;
}


.fr-table.fr-table--spaced th,
.fr-table.fr-table--spaced td {
    background: #f2f2f9;
    border-radius: 3px;
    padding: .5rem .75rem;
}

.fr-table.fr-table--spaced th {
    color: #000091;
}

main.modal {
    background: #fff;
    height: 100%;
}

.fr-alert.fr-alert--clear {
    padding: 1rem;
    background: #f2f2f9;
    box-shadow: none;
}

.fr-alert.fr-alert--info.fr-alert--clear:before {
    content: "";
    -webkit-mask-image: none;
    mask-image: none;
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
}

.fr-version {
    color: #929292 !important;
    font-weight: normal !important;
    align-self: center;
    padding: 0.25rem;
}

.fr-version--menu {
    color: #929292 !important;
    font-weight: normal !important;
    text-align: center;
    padding: 1rem;
}

.fr-radio-group--sm input[type=radio].checked+label:before {
    box-shadow: inset 0 0 0 1px var(--border-action-high-grey), inset 0 0 0 4px var(--background-default-grey), inset 0 0 0 8px var(--background-action-high-blue-france);
}

span.mobile-version {
    display: none;
}

span.desktop-version {
    display: block;
}

@media (max-width: 48em) {
    span.mobile-version {
        display: block;
    }

    span.desktop-version {
        display: none;
    }
}

.fr-icon-success {
    position: relative;
    top: -1px;
    color: var(--background-flat-success);
}
